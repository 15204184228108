import React from 'react'
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { MDXRenderer } from "gatsby-plugin-mdx"
import SEO from '../components/seo';

export const query = graphql`
  query($slug: String!) {
      mdx (
        frontmatter: {
          slug: {
            eq: $slug
          }
        }
      ) {
          frontmatter {
            date
            title
            slug
            tag
            thumbnail {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }        
          body
      }
  }
`
function BlogDetail(props) {
  return (
    <Layout>
      <SEO 
        title={props.data.mdx.frontmatter.title} 
        description={props.data.mdx.frontmatter.title}
        logoImage={props.data.mdx.frontmatter.thumbnail?.childImageSharp.fluid.src} />
      <div>
        <div className="my-4 p-4 lg:mx-4">
          <div className="p-4 bg-white rounded-lg markdown">
            <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogDetail
